import { Box, Button, TextField, Typography } from '@mui/material'
import { maxWidth } from '@mui/system';
import React, { useState } from 'react'
import { useContext } from 'react';
import Content from '../components/Content'
import { AuthContext } from '../context/AuthContext';
import { adminLogin } from '../services/User';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const [isAuthorized, setIsAuthorized] = useContext(AuthContext)

    const handleSubmit = async () => {
        try {
            let obj = {
                email,
                password
            }
            const res = await adminLogin(obj)
            localStorage.setItem('AUTH_TOKEN', res.data.data)
            localStorage.setItem('AUTH_REFRESH_TOKEN',res.data.refreshToken)
            setIsAuthorized(true)
            console.log(res.data)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            minWidth: '100vw'
        }}>
            <Content>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p:3,
                    width:'50vw',
                    maxWidth:400,
                    minWidth:300,
                }}>
                    {/* <Typography variant="h4" gutterBottom component="div">
                        Electura
                    </Typography> */}
                    <Typography variant="h4" gutterBottom component="div">
                        Login
                    </Typography>
                    <TextField label="Email" onChange={(e) => setEmail(e.target.value)} variant="outlined" value={email} sx={{mt:2 }} />
                    <TextField label="Password" onChange={(e) => setPassword(e.target.value)} variant="outlined" value={password} type={'password'} sx={{ mt: 1 }} />
                    <Button variant='contained' sx={{mt:1}} size="large" onClick={()=>handleSubmit()} >
                    <Typography variant="h6" component="div">
                        Login
                    </Typography>
                    </Button>
                </Box>
            </Content>
        </Box>
    )
}
