import { url } from './constants'
import { axiosApiInstance } from '../App';
const axios = require('axios');


const serverUrl = `${url}category`

export const categoryAdd = async (formData) => {
    return axiosApiInstance.post(`${serverUrl}/`, formData)
}


export const getAllCategory = async () => {
    return axiosApiInstance.get(`${serverUrl}/getRootCategory`)
}

export const deleteCategory = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`)
}

export const editCategory = async (id, obj) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, obj)
}

export const getByParentId = async (id) => {
    return axiosApiInstance.get(`${serverUrl}/getByParentId/${id}`)
}


export const getCategoryById = async (id) => {
    return axiosApiInstance.get(`${serverUrl}/getById/${id}`)
}


