import { axiosApiInstance } from '../App';
import { url } from './constants'
const axios = require('axios').default;


const serverUrl = `${url}users`

export const adminLogin = async (formData) => {
    console.log(formData)
    return axiosApiInstance.post(`${serverUrl}/loginAdmin`, formData)
}




export const registerUser = async (formData) => {
    console.log(formData)
    return axiosApiInstance.post(`${serverUrl}/register`, formData)
}
export const approveUser = async (id) => {
    return axiosApiInstance.patch(`${serverUrl}/approveUser/${id}`)
}

export const getAllUsers = async () => {
    return axiosApiInstance.get(`${serverUrl}/getAllUsers`)
}



export const removeUserById = (id) => {
    return axiosApiInstance.patch(`${serverUrl}/deleteUserById/${id}`)
}

export const sendNotificationByAdmin = async (obj) => {
    return axiosApiInstance.post(`${serverUrl}/sendNotificationByAdmin`, obj)
}