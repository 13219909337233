import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Content from '../../components/Content';
import { plyAdd } from '../../services/Ply';


export default function PlyAdd() {

    const params = useParams();
    const history = useHistory();

    const [ply, setPly] = useState(0);
    const [specVirgin, setSpecVirgin] = useState(0);
    const [NoOfSpec, setNoOfSpec] = useState(0);
    const [NoOfSpecSemi, setNoOfSpecSemi] = useState(0);

    const [specSemi, setSpecSemi] = useState(0);

    const handleSubmit = async () => {
        try {
            let obj = {
                ply: ply,
                specVirgin,
                NoOfSpec,
                NoOfSpecSemi,
                specSemi
            }

            
            const { data: res } = await plyAdd(obj);
            if (res.success) {
                alert(res.message)
            }
            history.goBack()
        } catch (error) {
            console.error(error)
            alert(error)
        }
    }







    return (
        <Content>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '50ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <Typography variant="h4" gutterBottom component="div">
                    Add Configs
                </Typography>

                <TextField id="outlined-basic" type={"number"} label="Ply" onChange={(e) => setPly(e.target.value)} variant="outlined" value={ply} />
                <TextField id="outlined-basic" type={"number"} label="Spec Virgin" onChange={(e) => setSpecVirgin(e.target.value)} variant="outlined" value={specVirgin} />
                {/* <TextField id="outlined-basic" type={"number"} label="No of spec" onChange={(e) => setNoOfSpec(e.target.value)} variant="outlined" value={NoOfSpec} /> */}
                {/* <TextField id="outlined-basic" type={"number"} label="No of spec Semi" onChange={(e) => setNoOfSpecSemi(e.target.value)} variant="outlined" value={NoOfSpecSemi} /> */}

                <TextField id="outlined-basic" type={"number"} label="Spec Semi" onChange={(e) => setSpecSemi(e.target.value)} variant="outlined" value={specSemi} />


                <Stack spacing={2} direction="row">
                    <Button variant="contained" onClick={() => handleSubmit()}>Submit</Button>
                </Stack>
            </Box>
        </Content>
    )
}
