import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Content from '../../components/Content';
import { categoryAdd } from '../../services/Category';


export default function CategoryAdd() {

    const params = useParams();
    const history = useHistory();

    const [name, setName] = useState('');



    
    const handleSubmit = async () => {
        try {
            let obj = {
                name,
            }

            if (params.categoryId) {
                obj.categoryId = params.categoryId
            }
            console.log(obj)
            const { data: res } = await categoryAdd(obj);
            if (res.success) {
                alert(res.message)
            }
            history.goBack()
        } catch (error) {
            console.error(error)
            alert(error)
        }
    }







    return (
        <Content>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '50ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <Typography variant="h4" gutterBottom component="div">
                    Add {params?.id} {params?.id ? 'Subcategory' : 'Category'}
                </Typography>

                <TextField id="outlined-basic" label="Category Name" onChange={(e) => setName(e.target.value)} variant="outlined" value={name} />

                <Stack spacing={2} direction="row">
                    <Button variant="contained" onClick={() => handleSubmit()}>Submit</Button>
                </Stack>
            </Box>
        </Content>
    )
}
