import React, { useState, useEffect } from 'react'
import Content from '../../components/Content'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { classesAdd } from '../../services/Classes';
import Select from 'react-select';
import { getAllSubjects } from '../../services/Subject';
import { subAdminAdd } from '../../services/SubAdmin';
import { useHistory } from 'react-router-dom';


export default function SubAdminAdd() {
    const history = useHistory()
    const [name, setName] = useState('');
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async () => {
        try {
            if (name == "") {
                alert("Name is a required field");
            }
            else if (email == "") {
                alert("Email is a required field");
            }
            else if (phone == "" || phone.length < 10 || phone.length > 10) {
                alert("Enter a valid phone number");
            }
            else if (password == "") {
                alert("Password is a required field");
            }
            else {


                let obj = {
                    name,
                    email,
                    phone,
                    password
                }
                console.log(obj)
                let { data: res } = await subAdminAdd(obj);
                if (res.success) {
                    alert(res.message)
                    history.goBack()
                }
            }
        } catch (error) {
            if (error?.response?.data?.message) {
                console.error(error?.response?.data?.message)
                alert(error?.response?.data?.message)

            }
            else {
                alert(error)
                console.error(error)
            }
            // alert(error)
        }
    }



    useEffect(() => {
    }, [])

    return (
        <Content>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 2, width: '50ch', height: 50 },
                }}
                noValidate
                autoComplete="off"
            >
                <Typography variant="h4" gutterBottom component="div">
                    Add Sub-Admin
                </Typography>
                <TextField id="outlined-basic" label="Sub-Admin Name" onChange={(e) => setName(e.target.value)} variant="outlined" value={name} />
                <TextField id="outlined-basic" label="Phone Number" onChange={(e) => setPhone(e.target.value)} variant="outlined" value={phone} />
                <TextField id="outlined-basic" label="Email" onChange={(e) => setEmail(e.target.value)} variant="outlined" value={email} />
                <TextField id="outlined-basic" label="Password" onChange={(e) => setPassword(e.target.value)} variant="outlined" value={password} />
                <Stack spacing={2} direction="row">
                    <Button variant="contained" onClick={() => handleSubmit()}>Submit</Button>
                </Stack>
            </Box>
        </Content>
    )
}
