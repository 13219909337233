import CategoryAdd from "../screens/dashboard/CategoryAdd"
import CategoryView from "../screens/dashboard/CategoryView"
import CategoryEdit from "../screens/dashboard/CategoryEdit"

import SubAdminView from "../screens/dashboard/SubAdminView"
import SubAdminAdd from "../screens/dashboard/SubAdminAdd"
import SubAdminEdit from "../screens/dashboard/SubAdminEdit"

import ProductAdd from "../screens/dashboard/ProductAdd"
import ProductView from "../screens/dashboard/ProductView"
import ProductEdit from "../screens/dashboard/ProductEdit"
import EnquiryView from "../screens/dashboard/EnquiryView"
import UsersAdd from "../screens/dashboard/UsersAdd"
import UsersView from "../screens/dashboard/UsersView"
import OrderAdd from "../screens/dashboard/OrderAdd"
import OrderEdit from "../screens/dashboard/OrderEdit"
import OrderView from "../screens/dashboard/OrderView"
import OrderByUserId from "../screens/dashboard/OrderByUserId"
import PlyView from "../screens/dashboard/PlyView"
import PlyAdd from "../screens/dashboard/PlyAdd"

import EcomEnquiryView from "../screens/dashboard/EcomEnquiryView"

import GlobalConstraintsView from "../screens/dashboard/GlobalConstraintsView"
import GlobalConstraintsAdd from "../screens/dashboard/GlobalConstraintsAdd"
import GlobalConstraintsEdit from "../screens/dashboard/GlobalConstraintsEdit"




import BannerView from "../screens/dashboard/BannerView"
import BannerAdd from "../screens/dashboard/BannerAdd"
import BannerEdit from "../screens/dashboard/BannerEdit"

export const dashboardroutes = [
    {
        title: 'Dashboard',
        children: [
            {
                title: 'Sub Admin',
                path: '/subAdmin',
                useTabs: true,
                tabs: [
                    {
                        title: 'View',
                        component: SubAdminView,
                        path: '/subAdmin'
                    },
                    {
                        title: 'Add',
                        component: SubAdminAdd,
                        path: '/subAdmin/add'
                    },
                    {
                        title: 'Edit',
                        useSameTab: true,
                        component: SubAdminEdit,
                        path: '/subAdmin/edit/:id'
                    },

                ]
            },
            {
                title: 'Global Constraints',
                path: '/globalConstraints',
                useTabs: true,
                tabs: [
                    {
                        title: 'View',
                        component: GlobalConstraintsView,
                        path: '/globalConstraints'
                    },
                    {
                        title: 'Add',
                        component: GlobalConstraintsAdd,
                        path: '/globalConstraints/add'
                    },
                    {
                        title: 'Edit',
                        useSameTab: true,
                        component: GlobalConstraintsEdit,
                        path: '/globalConstraints/edit/:id'
                    },

                ]
            },

            {
                title: 'Category',
                path: '/category',
                useTabs: true,
                tabs: [
                    {
                        title: 'View',
                        component: CategoryView,
                        path: '/category'
                    },
                    {
                        title: 'View',
                        useSameTab: true,
                        component: CategoryAdd,
                        path: '/category/add'
                    },
                    {
                        title: 'View',
                        component: CategoryAdd,
                        useSameTab: true,
                        path: '/category/add/:categoryId'
                    },
                    { // edit
                        title: 'View', // tab name
                        component: CategoryEdit,
                        useSameTab: true,
                        path: '/category/edit/:id'
                    },
                    {
                        title: 'View',
                        useSameTab: true,
                        component: CategoryView,
                        path: '/category/sub/:categoryId'
                    },
                ]
            },

            {
                title: 'Banner',
                path: '/banner',
                useTabs: true,
                tabs: [
                    {
                        title: 'View',
                        component: BannerView,
                        path: '/banner'
                    },
                    {
                        title: 'View',
                        useSameTab: true,
                        component: BannerAdd,
                        path: '/bannerAdd/add'
                    },
                    // {
                    //     title: 'View',
                    //     component: CategoryAdd,
                    //     useSameTab: true,
                    //     path: '/category/add/:categoryId'
                    // },
                    { // edit
                        title: 'View', // tab name
                        component: BannerEdit,
                        useSameTab: true,
                        path: '/banner/edit/:id'
                    },
                ]
            },

            {
                title: 'Product',
                path: '/product',
                useTabs: true,
                tabs: [
                    {
                        title: 'View',
                        component: ProductView,
                        path: '/product'
                    },
                    {
                        title: 'View',
                        useSameTab: true,
                        component: ProductEdit,
                        path: '/product/edit/:id'
                    },
                    {
                        title: 'Add',
                        component: ProductAdd,
                        path: '/product/add'
                    },
                ]
            },
            {
                title: 'Enquiry',
                path: '/enquiry',
                useTabs: true,
                tabs: [
                    {
                        title: 'View',
                        component: EnquiryView,
                        path: '/enquiry'
                    },
                ]
            },
            {
                title: 'Ecommerce Enquiry',
                path: '/ecomEnquiry',
                useTabs: true,
                tabs: [
                    {
                        title: 'View',
                        component: EcomEnquiryView,
                        path: '/ecomEnquiry'
                    },
                ]
            },
            {
                title: 'Users',
                path: '/users',
                useTabs: true,
                tabs: [
                    {
                        title: 'View',
                        component: UsersView,
                        path: '/users'
                    },
                    {
                        title: 'Add',
                        component: UsersAdd,
                        path: '/users/add'
                    },
                ]
            },
            {
                title: 'Order',
                path: '/order',
                useTabs: true,
                tabs: [
                    {
                        title: 'View',
                        component: OrderView,
                        path: '/order'
                    },
                    {
                        title: 'View',
                        useSameTab: true,
                        component: OrderEdit,
                        path: '/order/edit/:id'
                    },
                    {
                        title: 'View',
                        useSameTab: true,
                        component: OrderByUserId,
                        path: '/order/viewByUserId/:id'
                    },
                    {
                        title: 'Add',
                        component: OrderAdd,
                        path: '/order/add'
                    },
                ]
            },
            {
                title: 'PlyGlobalConfigs',
                path: '/PlyGlobalConfigs',
                useTabs: true,
                tabs: [
                    {
                        title: 'View',
                        component: PlyView,
                        path: '/PlyGlobalConfigs'
                    },
                    
                    {
                        title: 'Add',
                        component: PlyAdd,
                        path: '/PlyGlobalConfigs/add'
                    },
                ]
            },


        ]
    },

]