import React, { useState, useEffect } from 'react'
import ContentTable from '../../components/ContentTable'
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { deleteproduct, getAllproducts } from '../../services/product';
import { deleteEnquiries, getAllEnquiries } from '../../services/Enquiries';
import { deleteEcomEnquiries, getAllEcomEnquiries } from '../../services/EcomEnquiry';


export default function EcomEnquiryView() {

    const [enquiryArr, setEnquiryArr] = useState([]);
    const [loading, setLoading] = useState(false)
    const history = useHistory();
    const params = useParams();

    const handleAllEnquiries = async () => {
        setLoading(true)
        try {
            const { data: res } = await getAllEcomEnquiries();
            if (res.success) {
                console.log(res.data)
                setEnquiryArr(res.data)
            }
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    const del = async (id) => {
        try {
            if (window.confirm('Are you sure you wan to delete?')) {
                let res = await deleteEcomEnquiries(id)
                if (res.data?.message) {
                    alert(res?.data.message)
                }
                setLoading(true);
                handleAllEnquiries();
            }
        } catch (error) {
            console.error(error)
        }
    }


    useEffect(() => {
        handleAllEnquiries()
    }, [params])

    return (
        <div>
            <ContentTable
                loading={loading}
                reload={handleAllEnquiries}
                data={enquiryArr}
                columns={[
                    {
                        name: 'Name',
                        selector: row => row.name,
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Email',
                        selector: row => row.email,
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Phone',
                        selector: row => row.phone,
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Company',
                        selector: row => row.company,
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Product Name',
                        selector: row => row.productName,
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Range',
                        selector: row => row.range,
                        sortable: true,
                        searchable: true,
                    },
                    
                ]}
            />
        </div>
    )
}
