import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Content from "../../components/Content";
import { bannerAdds } from "../../services/Banner";

export default function BannerAdd() {
  const params = useParams();
  const history = useHistory();

  const [image, setImage] = useState({});

  const handleSubmit = async () => {
    try {
      let formData = new FormData();

      formData.append("file", image);
      // if (params.imageId) {
      //     obj.imageId = params.imageId
      // }
      //   console.log(f, "2342323423");
      const { data: res } = await bannerAdds(formData);
      if (res.success) {
        alert(res.message);
      }
      history.goBack();
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  return (
    <Content>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "50ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <Typography variant="h4" gutterBottom component="div">
          Add {params?.id} {params?.id ? "Image" : "Image"}
        </Typography>

        {/* <TextField id="outlined-basic" label="Banner image" onChange={(e) => setImage(e.target.value)} variant="outlined" value={image} /> */}

        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Upload Banner</label>
          <TextField
            id="outlined-basic"
            type={"file"}
            onChange={(e) => setImage(e.target.files[0])}
            variant="outlined"
          />
        </div>

        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={() => handleSubmit()}>
            Submit
          </Button>
        </Stack>
      </Box>
    </Content>
  );
}
