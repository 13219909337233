import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Content from '../../components/Content';
import { categoryAdd } from '../../services/Category';
import { registerUser } from '../../services/User';

export default function UsersAdd() {

    const params = useParams();
    const history = useHistory();
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleSubmit = async () => {
        try {
            if (name == "") {
                alert("Please Enter a name")
            }
            else if (email == "") {
                alert("Please Enter an email")
            }
            else if (phone == "") {
                alert("Please Enter a phone number")
            }
            else if (phone.length < 10) {
                alert("Please Enter a valid phone number")
            }
            else if (password != confirmPassword) {
                alert("Password and confirm password does not match")
            }
            else {

                let obj = {
                    name,
                    email,
                    phone,
                    password
                }
                const { data: res } = await registerUser(obj);
                if (res.success) {
                    alert(res.message)
                }
                history.goBack()

            }

        } catch (error) {
            console.error(error)
            alert(error)
        }
    }







    return (
        <Content>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '50ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <Typography variant="h4" gutterBottom component="div">
                    Add User
                </Typography>

                <TextField id="outlined-basic" label="Name" onChange={(e) => setName(e.target.value)} variant="outlined" value={name} />
                <TextField id="outlined-basic" label="Email" onChange={(e) => setEmail(e.target.value)} variant="outlined" value={email} />
                <TextField id="outlined-basic" label="Phone" onChange={(e) => setPhone(e.target.value)} variant="outlined" value={phone} />
                <TextField id="outlined-basic" label="Password" onChange={(e) => setPassword(e.target.value)} variant="outlined" value={password} />
                <TextField id="outlined-basic" label="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} variant="outlined" value={confirmPassword} />

                <Stack spacing={2} direction="row">
                    <Button variant="contained" onClick={() => handleSubmit()}>Submit</Button>
                </Stack>
            </Box>
        </Content>
    )
}
