import { MenuItem, Select, Switch, TextareaAutosize } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Content from '../../components/Content';
import { getAllCategory, getByParentId } from '../../services/Category';
import { productAdd, updateImageById } from '../../services/product';
// import { getAllsizes } from '../../services/Size';
// import { wallAdd } from '../../services/Wall';

export default function WallAdd() {
    const history = useHistory()
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [categoryArr, setCategoryArr] = useState([]);
    const [description, setDescription] = useState('');
    const [currentCategoryArr, setCurrentCategoryArr] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [title, setTitle] = useState("")
    const [isInStock, setIsInStock] = useState(false);

    ///////selected arrays
    const [selectedCategoriesArr, setSelectedCategoriesArr] = useState([]);

    const [imageArr, setImageArr] = useState([]);
    const [displayImageArr, setDisplayImageArr] = useState([]);
    const handleSubmit = async () => {
        try {
            let catId = selectedCategoriesArr[selectedCategoriesArr.length - 1]._id
            let obj = {
                name,
                price,
                categoryId: catId,
                description: description,
                categoryArr: selectedCategoriesArr.map(el => { return { categoryId: el._id } }),
                isActive,
                isInStock,
                title,
            }
            console.log(obj)
            const { data: res } = await productAdd(obj);
            if (res.success) {
                if (displayImageArr && displayImageArr.length > 0) {
                    for (let el of imageArr) {
                        let tempFormData = new FormData();
                        tempFormData.append('file', el);
                        let response = await updateImageById(res.data, tempFormData);
                        if (response.data.success) {
                            alert(res.message)
                            history.goBack()
                        }
                    }
                }
            }
        } catch (error) {
            console.error(error)
            alert(error)
        }
    }

    const handleGetCategory = async () => {
        try {
            const { data: res } = await getAllCategory();
            if (res.success) {
                console.log(res.data)
                setCategoryArr(res.data.map(el => {
                    let obj = {
                        value: el._id,
                        label: el.name,
                        ...el
                    }
                    return obj
                }))
                setCurrentCategoryArr([res.data.map(el => {
                    let obj = {
                        value: el._id,
                        label: el.name,
                        ...el
                    }
                    return obj
                })])
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleCategorySelection = async (e, index) => {
        if (e) {
            e = JSON.parse(e)
            try {
                const { data: res } = await getByParentId(e._id);
                if (res.success) {
                    let tempArr = [...currentCategoryArr]
                    setSelectedCategoryId(e._id)
                    let tempCategoryArr = [...selectedCategoriesArr]
                    if (selectedCategoriesArr.findIndex(el => el.level > e.level)) {
                        tempCategoryArr = tempCategoryArr.filter(el => el.level < e.level)
                    }
                    tempCategoryArr.push(e)
                    setSelectedCategoriesArr(tempCategoryArr)
                    if (res.data.length > 0) {
                        if (tempArr.findIndex(el => el.some(elx => elx.level > e.level)) != -1) {
                            tempArr = tempArr.filter(el => el.some(elx => (elx.level <= e.level)))
                            console.log(tempArr, "tempArr")
                        }
                        tempArr.push(res.data.map(el => {
                            let obj = {
                                value: el._id,
                                label: el.name,
                                ...el
                            }
                            return obj
                        }))
                    }
                    setCurrentCategoryArr([...tempArr])
                }
            } catch (error) {
                console.error(error)
            }

            console.log(e)

        }
        else {
            let tempArr = [...currentCategoryArr]
            let tempCategoryArr = [...selectedCategoriesArr]
            if (selectedCategoriesArr.findIndex(el => el.level > e.level)) {
                tempCategoryArr = tempCategoryArr.filter((el, i) => i < index)
            }
            tempCategoryArr.push(e)
            setSelectedCategoriesArr(tempCategoryArr)
            tempArr = tempArr.filter((el, i) => i <= index)
            setCurrentCategoryArr([...tempArr])

        }
    }

    const handleFileSelection = (event) => {
        if (event.target.files[0] && event.target.files[0].name) {

            let tempArr = [...imageArr]
            tempArr.push(event.target.files[0])
            console.log(tempArr)
            setImageArr([...tempArr])


            let tempDisplayArr = [...displayImageArr]
            tempDisplayArr.push(event.target.files[0].name)

            setDisplayImageArr(tempDisplayArr)
            console.log(tempDisplayArr)

        }
    }
    const handleImageDelete = (index) => {
        let arr = [...displayImageArr]
        arr.splice(index, 1)
        setDisplayImageArr([...arr])


        let tempArr = [...imageArr]
        tempArr.splice(index, 1)
        setImageArr([...tempArr])
    }

    useEffect(() => {
        console.log('isActive', isActive)
        handleGetCategory()
    }, [isActive])

    return (
        <Content>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '100ch' },
                    display: 'flex', flexDirection: 'column'
                }}
                noValidate
                autoComplete="off"
            >
                <Typography variant="h4" gutterBottom component="div">
                    Add Product
                </Typography>
                <TextField id="outlined-basic" label="Name" onChange={(e) => setName(e.target.value)} variant="outlined" value={name} />
                <TextField id="outlined-basic" label="Title" onChange={(e) => setTitle(e.target.value)} variant="outlined" value={title} />
                <label>Categories</label>
                {
                    currentCategoryArr.map((el, index) => {
                        return (
                            <>
                                {/* <Select value={selectedCategoriesArr[index] ? selectedCategoriesArr[index].value : ""} key={index} options={el} onChange={(e) => handleCategorySelection(e)} placeholder="Select Course Category" /> */}
                                <Select key={index} value={selectedCategoriesArr[index] ? JSON.stringify(selectedCategoriesArr[index]) : ""} onChange={(e) => handleCategorySelection(e.target.value, index)}>
                                    <MenuItem value={''} >Please select a value</MenuItem>
                                    {
                                        el.map((ele) => <MenuItem key={ele._id} value={JSON.stringify(ele)}>{ele.name}</MenuItem>)
                                    }
                                </Select>
                            </>
                        )
                    })

                }
                <TextField id="outlined-basic" type="number" label="Price" onChange={(e) => setPrice(e.target.value)} variant="outlined" value={price} />

                <TextareaAutosize style={{ height: 100, width: 900, borderRadius: 10 }} id="outlined-basic" placeholder="Description" onChange={(e) => setDescription(e.target.value)} variant="outlined" value={description} />



                <div className="col-1">
                    <label htmlFor="">Active</label>
                    <Switch checked={isActive} onChange={(e) => setIsActive(e.target.checked)} defaultChecked />
                </div>
                <div className="col-1">
                    <label htmlFor="">In Stock</label>
                    <Switch checked={isInStock} onChange={(e) => { setIsInStock(e.target.checked); console.log('e.target.checked', e.target.checked) }} />
                </div>


                <label>Product Images</label>
                <input type="file" onChange={(e) => handleFileSelection(e)} />
                {
                    displayImageArr && displayImageArr.map((el, index) => {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <span key={index}>{index + 1}. {el}</span>
                                <Button variant="contained" color='error' onClick={() => handleImageDelete(index)}>Delete</Button>
                            </div>
                        )
                    })

                }
                <Stack spacing={2} direction="row">
                    <Button variant="contained" onClick={() => handleSubmit()}>Submit</Button>
                </Stack>
            </Box>
        </Content>
    )
}
