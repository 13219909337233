import { axiosApiInstance } from '../App';
import { url } from './constants'
const axios = require('axios');


const serverUrl = `${url}size`

export const sizeAdd = async (formData) => {
    return axiosApiInstance.post(`${serverUrl}/`, formData)
}

export const getAllsizes = async () => {
    return axiosApiInstance.get(`${serverUrl}/`)
}

export const getSIzeById = async (id) => {
    return axiosApiInstance.get(`${serverUrl}/getById/${id}`)
}

export const deletesizes = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`)
}

export const editsizes = async (id, obj) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, obj)
}
