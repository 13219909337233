import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { ProtectedRoute, UnProtectedRoute } from "./components/AuthRoutes";
import Paperbase from "./paperbase/Paperbase";
import Login from "./screens/Login";
import Register from "./screens/Register";

export default function MainRouter() {
    return (
    <Router>
      <Switch>
        <UnProtectedRoute path="/" exact component={Login} />
        {/* <UnProtectedRoute path="/register" exact component={Register} /> */}
        <ProtectedRoute path="/dashboard" component={Paperbase} />
      </Switch>
    </Router>
    );
}