import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Content from '../../components/Content';
import { categoryAdd } from '../../services/Category';
import { globalConstraintsAdd } from '../../services/GlobalConstraints';
import { sizeAdd } from '../../services/Size';


export default function GlobalConstraintsAdd() {

    const params = useParams();
    const history = useHistory();

    const [costVirgin, setCostVirgin] = useState('');
    const [costSemi, setCostSemi] = useState('');
    const [costOfStickers, setCostOfStickers] = useState('');
    const handleSubmit = async () => {
        try {
            let obj = {
                costVirgin: costVirgin,
                costSemi: costSemi,
                costOfStickers
            }
            const { data: res } = await globalConstraintsAdd(obj);
            if (res.success) {
                alert(res.message)
            }
            history.goBack()
        } catch (error) {
            if (error?.response?.data?.message) {

                console.log(error?.response?.data?.message)
                alert(error?.response?.data?.message)
            }
            else {
                alert(error)
                console.log(error)
            }
        }
    }







    return (
        <Content>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '50ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <Typography variant="h4" gutterBottom component="div">
                    Add Size
                </Typography>

                <TextField id="outlined-basic" label="Cost Virgin" type={"number"} onChange={(e) => setCostVirgin(e.target.value)} variant="outlined" value={costVirgin} />
                <TextField id="outlined-basic" label="Cost Semi" type={"number"} onChange={(e) => setCostSemi(e.target.value)} variant="outlined" value={costSemi} />
                <TextField id="outlined-basic" label="Cost Of Stickers" type={"number"} onChange={(e) => setCostOfStickers(e.target.value)} variant="outlined" value={costOfStickers} />

                <Stack spacing={2} direction="row">
                    <Button variant="contained" onClick={() => handleSubmit()}>Submit</Button>
                </Stack>
            </Box>
        </Content>
    )
}
