import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import PermMediaOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActual';
import PublicIcon from '@mui/icons-material/Public';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import TimerIcon from '@mui/icons-material/Timer';
import SettingsIcon from '@mui/icons-material/Settings';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';

import { dashboardroutes } from './dashboard.route'
import { useHistory } from 'react-router-dom';

const categories = [
  // {
  //   id: 'Build',
  //   children: [
  //     {
  //       id: 'Authentication',
  //       icon: <PeopleIcon />,
  //       active: true,
  //     },
  //     // { id: 'Database', icon: <DnsRoundedIcon /> },
  //     // { id: 'Storage', icon: <PermMediaOutlinedIcon /> },
  //     // { id: 'Hosting', icon: <PublicIcon /> },
  //     // { id: 'Functions', icon: <SettingsEthernetIcon /> },
  //     // {
  //     //   id: 'Machine learning',
  //     //   icon: <SettingsInputComponentIcon />,
  //     // },
  //   ],
  // },
  // // {
  // //   id: 'Quality',
  // //   children: [
  // //     { id: 'Analytics', icon: <SettingsIcon /> },
  // //     { id: 'Performance', icon: <TimerIcon /> },
  // //     { id: 'Test Lab', icon: <PhonelinkSetupIcon /> },
  // //   ],
  // // },
  // {
  //   id:'Category',
  //   children:[
  //     {id:'add-category',}
  //   ]
  // }
];

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
  cursor: 'pointer'
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export default function Navigator(props) {
  const { ...other } = props;
  const history = useHistory()

  const checkIsSelected = () => {
    return false
  }
  const handleLogout = () => {
    localStorage.removeItem('AUTH_TOKEN')
    localStorage.removeItem('AUTH_REFRESH_TOKEN')
    window.location.reload()
  }

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
          JSH
        </ListItem>
        {/* <ListItem sx={{ ...item, ...itemCategory }}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText>Project Overview</ListItemText>
        </ListItem> */}
        {dashboardroutes.map(({ title, children }, index) => (
          <Box key={index} sx={{ bgcolor: '#101F33' }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: '#fff' }}>{title}</ListItemText>
            </ListItem>
            {children.filter(el => el.useTabs).map(({ title, path, icon: Icon }, j) => (
              <ListItem disablePadding key={`${index}_${j}`} onClick={() => history.push('/dashboard' + path)}>
                <ListItemButton selected={checkIsSelected()} sx={item}>
                  {
                    Icon &&
                    <ListItemIcon><Icon /></ListItemIcon>
                  }
                  <ListItemText>{title}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}

            <Divider sx={{ mt: 2 }} />
            <ListItem onClick={() => handleLogout()} sx={item}>
              <ListItemText sx={{ color: '#fff' }}>Logout</ListItemText>
            </ListItem>
          </Box>
        ))}


        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#101F33' }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active }) => (
              <ListItem disablePadding key={childId}>
                <ListItemButton selected={checkIsSelected()} sx={item}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}

            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
