import { url } from './constants'
import { axiosApiInstance } from '../App';
const axios = require('axios');


const serverUrl = `${url}enquiry`



export const getAllEnquiries = async () => {
    return axiosApiInstance.get(`${serverUrl}/`)
}

export const deleteEnquiries = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`)
}
