import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Content from "../../components/Content";


import { editBanner, getAllBanner } from "../../services/Banner";

export default function BannerEdit() {
  const params = useParams();
  const history = useHistory();

  const [image, setImage] = useState("");

  const handleSubmit = async () => {
    try {
      let obj = {
        image,
      };
      const { data: res } = await editBanner(params.id, obj);
      if (res.success) {
        alert(res.message);
      }
      history.goBack();
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  const handleGet = async () => {
    try {
      const { data: res } = await getAllBanner(params.id);
      if (res.data) {
        setImage(res.data.image);
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  useEffect(() => {
    handleGet();
  }, []);

  return (
    <Content>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "50ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <Typography variant="h4" gutterBottom component="div">
          Edit Banner
        </Typography>

        <TextField
          id="outlined-basic"
          label="Category image"
          onChange={(e) => setImage(e.target.value)}
          variant="outlined"
          value={image}
        />

        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={() => handleSubmit()}>
            Update
          </Button>
        </Stack>
      </Box>
    </Content>
  );
}
