import React, { useState, useEffect } from 'react'
import ContentTable from '../../components/ContentTable'
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { deleteproduct, getAllproducts } from '../../services/product';
export default function ProductView() {

    const [wallArr, setWallArr] = useState([]);
    const [loading, setLoading] = useState(false)
    const history = useHistory();
    const params = useParams();

    const handleGetCategory = async () => {
        setLoading(true)
        try {
            const { data: res } = await getAllproducts();
            if (res.success) {
                setWallArr(res.data)
            }
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    const del = async (id) => {
        try {
            if (window.confirm('Are you sure you wan to delete?')) {
                let res = await deleteproduct(id)
                if (res.data?.message) {
                    alert(res?.data.message)
                }
                setLoading(true);
                handleGetCategory();
            }
        } catch (error) {
            console.error(error)
        }
    }


    useEffect(() => {
        handleGetCategory()
    }, [params])

    return (
        <div>
            <ContentTable
                loading={loading}
                reload={handleGetCategory}
                data={wallArr}
                columns={[
                    {
                        name: 'Name',
                        selector: row => row.name,
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Description',
                        cell: row => <div style={{ width: 200, paddingBottom: 15 }}>{row.description}</div>,
                        sortable: true,
                        searchable: true,
                    },

                    {
                        name: 'Price',
                        selector: row => `INR ${row.price}`,
                        sortable: true,
                        searchable: true,
                    },

                    {
                        name: 'Category',
                        selector: row => row.savedCategoryArr.reduce((acc, el, index) => acc + `${el.name}${index == row.savedCategoryArr.length - 1 ? '' : '->'}`, ""),
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Edit',
                        cell: row => <Button variant='contained' onClick={() => history.push(`/dashboard/product/edit/${row._id}`)}>  Edit </Button>,
                    },
                    {
                        name: 'Delete',
                        cell: row => <Button variant='contained' color='error' onClick={() => del(row._id)} > Delete</Button>,
                    },
                ]}
            />
        </div>
    )
}
