import { url } from './constants'
import { axiosApiInstance } from '../App';
const axios = require('axios');


const serverUrl = `${url}ecomEnquiry`



export const getAllEcomEnquiries = async () => {
    return axiosApiInstance.get(`${serverUrl}/`)
}

export const deleteEcomEnquiries = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`)
}
