import React, { useState, useEffect } from 'react'
import ContentTable from '../../components/ContentTable'
import { deleteCategory, getAllCategory, getByParentId } from '../../services/Category';
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { deleteSubAdmins, getAllSubAdmins } from '../../services/SubAdmin';
export default function SubAdminView() {

    const [subAdminArr, setSubAdminArr] = useState([]);
    const [loading, setLoading] = useState(false)
    const history = useHistory();
    const params = useParams();

    const handleGetSubAdmins = async () => {
        setLoading(true)
        try {
            const { data: res } = await getAllSubAdmins();
            if (res.success) {
                console.log(res.data)
                setSubAdminArr(res.data)
            }
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    const del = async (id) => {
        try {
            if (window.confirm('Are you sure you wan to delete?')) {
                let res = await deleteSubAdmins(id)
                if (res.data?.message) {
                    alert(res?.data.message)
                }
                setLoading(true);
                handleGetSubAdmins();
            }
        } catch (error) {
            console.error(error)
        }
    }


    useEffect(() => {
        handleGetSubAdmins()
    }, [params])

    return (
        <div>
            <ContentTable
                loading={loading}
                reload={handleGetSubAdmins}
                data={subAdminArr}
                columns={[
                    {
                        name: 'Name',
                        selector: row => row.name,
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Email',
                        selector: row => row.email,
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Phone',
                        selector: row => row.phone,
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Edit',
                        cell: row => <Button variant='contained' onClick={() => history.push(`/dashboard/subAdmin/edit/${row._id}`)}>  Edit </Button>,
                    },
                    {
                        name: 'Delete',
                        cell: row => <Button variant='contained' color='error' onClick={() => del(row._id)} > Delete</Button>,
                    },
                ]}
            />
        </div>
    )
}
