import React, { useState, useEffect, createContext, useMemo } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios';
import { url } from '../services/constants';
import { axiosApiInstance } from '../App'

export const AuthContext = createContext();


export function AuthProvider(props) {
  const [isAuthorized, setIsAuthorized] = useState();

  useEffect(() => {
    let token = localStorage.getItem('AUTH_TOKEN')
    if (token)
      setIsAuthorized(true)
    else
      setIsAuthorized(false)
  }, [])

  useMemo(() => {
    console.log('Setting Interceptor')
    axiosApiInstance.interceptors.request.use(
      config => {
        const token = localStorage.getItem('AUTH_TOKEN')
        if (token) {
          config.headers['Authorization'] = 'Bearer ' + token;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
      },
      error => {
        return Promise.reject(error)
      });

    axiosApiInstance.interceptors.response.use(
      (res) => {
        // Add configurations here
        return res;
      },
      async (err) => {
        const originalConfig = err.config;
        // if (err?.response?.status === 401) {
        //   // console.log("ONLY IN ERROR")
        //   // toast.error('401 Unauthorized')
        //   localStorage.removeItem('AUTH_TOKEN')
        //   setIsAuthorized(false)
        // }
        // if(err?.response?.data?.message){
        //     toast.error(err?.response?.data?.message)
        // }
        // else{
        //     toast.error(err.message)
        // }
        // return Promise.reject(err);

        // if (originalConfig.url !== `${url}users/login/` && err.response) {
        //   // Access Token was expired
        //   if (err.response.status === 401 && !originalConfig._retry) {
        //     // let authToken = localStorage.getItem('AUTH_TOKEN')
        //     // localStorage.removeItem('AUTH_TOKEN')
        //     originalConfig._retry = true;


        //     try {
        //       let token = localStorage.getItem('AUTH_REFRESH_TOKEN')
        //       const rs = await axios.get(`${url}users/generateNewAccessToken/${token}`)
        //       console.log(rs.data)
        //       if (rs.data.data) {
        //         localStorage.setItem('AUTH_TOKEN', rs.data.data)
        //         return axiosApiInstance(originalConfig)
        //       }
        //       else {
        //         throw new Error(err);

        //       }

        //     } catch (error) {
        //       localStorage.removeItem('AUTH_TOKEN')
        //       localStorage.removeItem('AUTH_REFRESH_TOKEN')
        //       setIsAuthorized(false)

        //       return Promise.reject(error);
        //     }
        //   }
        // }
        // if (err?.response?.data?.message) {
        //   toast.error(err?.response?.data?.message)
        // }
        // else {
        //   toast.error(err.message)
        // }
        return Promise.reject(err);


      }
    );
  }, [])


  return (
    <AuthContext.Provider value={[isAuthorized, setIsAuthorized]}>
      {props.children}
    </AuthContext.Provider>
  )
}
