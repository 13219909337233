import { axiosApiInstance } from '../App';
import { url } from './constants'
const axios = require('axios');


const serverUrl = `${url}users`

export const subAdminAdd = async (formData) => {
    return axiosApiInstance.post(`${serverUrl}/registerSubAdmin`, formData)
}


export const getAllSubAdmins = async () => {
    return axiosApiInstance.get(`${serverUrl}/getAllSubAdmins`)
}


export const getUserById = async (id) => {
    return axiosApiInstance.get(`${serverUrl}/getById/${id}`)
}




export const deleteSubAdmins = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`)
}
export const editSubAdmins = async (id, obj) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, obj)
}