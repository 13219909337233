import { url } from './constants'
import { axiosApiInstance } from '../App';
const axios = require('axios');


const serverUrl = `${url}banner`

export const bannerAdds = async (formData) => {
    return axiosApiInstance.post(`${serverUrl}/`, formData)
}

export const getAllBanner = async () => {
    return axiosApiInstance.get(`${serverUrl}/`)
}

export const deleteBanner = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`)
}

export const editBanner = async (id, obj) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, obj)
}
