import React, { useState, useContext, useEffect } from 'react'
import { Route, Redirect } from "react-router-dom"
import {AuthContext} from '../context/AuthContext'

export function ProtectedRoute({component:Component, ...rest}) {
  const [isAuthorized, setIsAuthorized] = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  useEffect(()=>{
    console.log(isAuthorized)
    if(isAuthorized == true || isAuthorized == false)
      setLoading(false)
  },[isAuthorized])

  return (
            <Route {...rest} render={() => {
                if(!loading)
                {
                if (isAuthorized) {
                    return <Component />
                }
                else {
                    return <Redirect to={'/'} />
                }
                }
                else{
                    return (<div>Loading...</div>)
                }

            }} />
        )
}

export function UnProtectedRoute({component:Component, ...rest}) {
  const [isAuthorized, setIsAuthorized] = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  useEffect(()=>{
    if(isAuthorized == true || isAuthorized == false)
      setLoading(false)
  },[isAuthorized])

  return (
            <Route {...rest} render={() => {
                if(!loading)
                {
                if (!isAuthorized) {
                    return <Component />
                }
                else {
                    return <Redirect to={'/dashboard'} />
                }
                }
                else{
                    return (<div>Loading...</div>)
                }

            }} />
        )
}
