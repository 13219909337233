import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useHistory, useLocation } from 'react-router-dom';

const lightColor = 'rgba(255, 255, 255, 0.7)';

function Header({ useTabs, title, tabs, ...props }) {
  const { onDrawerToggle } = props;
  const location = useLocation();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(0)
  useEffect(() => {
    const getAndSetSelectedTab = () => {
      if (!useTabs || !tabs)
        setSelectedTab(0)
      else {
        let obj = tabs.reduceRight((acc, el) => acc ? acc : location.pathname.includes(el?.path?.split(':')[0]) ? el : null, null)
        let index = tabs.findIndex(el => el.title == obj.title)
        if (index >= 0) {
          setSelectedTab(index)
        }
        else {
          setSelectedTab(0)
        }
      }
    }
    getAndSetSelectedTab()
    console.log(location.pathname)
  }, [location, tabs, useTabs]);



 


  return (
    <>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          {/* <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Link
                href="/"
                variant="body2"
                sx={{
                  textDecoration: 'none',
                  color: lightColor,
                  '&:hover': {
                    color: 'common.white',
                  },
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Go to docs
              </Link>
            </Grid>
            <Grid item>
              <Tooltip title="Alerts • No alerts">
                <IconButton color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton color="inherit" sx={{ p: 0.5 }}>
                <Avatar src="/static/images/avatar/1.jpg" alt="My Avatar" />
              </IconButton>
            </Grid>
          </Grid> */}
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {title}
              </Typography>
            </Grid>
            {/* <Grid item>
              <Button
                sx={{ borderColor: lightColor }}
                variant="outlined"
                color="inherit"
                size="small"
              >
                Web setup{useTabs && JSON.stringify(tabs)}
              </Button>
            </Grid>
            <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
          </Grid>
        </Toolbar>
      </AppBar>
      {
        useTabs &&
        <AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0 }}>
          <Tabs value={selectedTab} textColor="inherit">
            {tabs.filter(el => !el.useSameTab).map((el, i) => (
              <Tab label={el.title} key={i} onClick={() => history.push('/dashboard' + el.path)} />
            ))}
          </Tabs>
         
        </AppBar>
      }

    </>
  );
}

// Header.propTypes = {
//   onDrawerToggle: PropTypes.func.isRequired,
// };

export default Header;
