import React, { useState, useEffect } from 'react'
import ContentTable from '../../components/ContentTable'
import { deleteCategory, getAllCategory, getByParentId, getCategoryById } from '../../services/Category';
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
export default function CategoryView(props) {

  const [categoryArr, setCategoryArr] = useState([]);
  const [categoryObj, setCategoryObj] = useState(null);
  const [loading, setLoading] = useState(false)
  const history = useHistory();
  const params = useParams();

  const handleGetCategory = async () => {
    setLoading(true)
    try {
      if (!params.categoryId) {
        // get level 1 only
        setCategoryObj(null)
        const { data: res } = await getAllCategory();
        if (res.success) {
          console.log(res.data)
          setCategoryArr(res.data)
        }
      }
      else {
        // get sub categories only
        getCategoryObj()
        const { data: res } = await getByParentId(params.categoryId);
        if (res.success) {
          console.log(res.data)
          setCategoryArr(res.data)
        }

      }
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  const getCategoryObj = async () => {
    try{
        const { data: res } = await getCategoryById(params.categoryId);
        if (res.success) {
          console.log(res.data)
          setCategoryObj(res.data)
        }
    }
    catch(err){
      console.error(err)
    }

  }

  const del = async (id) => {
    try {
      if (window.confirm('Are you sure you wan to delete?')) {
        let res = await deleteCategory(id)
        if (res.data?.message) {
          alert(res?.data.message)
        }
        setLoading(true);
        handleGetCategory();
      }
    } catch (error) {
      console.error(error)
    }
  }


  useEffect(() => {
    handleGetCategory()
  }, [params])

  return (
    <div>
      <ContentTable
        loading={loading}
        reload={handleGetCategory}
        data={categoryArr}
        extraButtonFn={()=>params?.categoryId ? history.push(`/dashboard/category/add/${params.categoryId}`) : history.push(`/dashboard/category/add/`)}
        extraButtonText={'Add'}
        title={categoryObj ?  `Sub Categories of ${categoryObj?.name}` : ''}
        columns={[
          {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            searchable: true,
          },
          // {
          //   name: 'Add',
          //   cell: row => <Button variant='contained' onClick={() => history.push(`/dashboard/category/add/${row._id}`)}>  Add </Button>,
          // },
          {
            name: 'View',
            cell: row => <Button variant='contained' onClick={() => history.push(`/dashboard/category/sub/${row._id}`)}>  View </Button>,
          },
          {
            name: 'Edit',
            cell: row => <Button variant='contained' onClick={() => history.push(`/dashboard/category/edit/${row._id}`)}>  Edit </Button>,
          },
          {
            name: 'Delete',
            cell: row => <Button variant='contained' color='error' onClick={() => del(row._id)} > Delete</Button>,
          },
        ]}
      />
    </div>
  )
}
