import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { LinearProgress } from '@mui/material';

function Content({ headerComponent : Header, loading , ...props}) {
  return (
    <Paper  sx={{
    maxWidth: 'min(100% , 100vw)',
    padding: (theme)=> theme.spacing(2),
    margin:(theme)=> theme.spacing(1),
    }}>
      {
      Header &&
      <AppBar sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', }} position="static" color="default" elevation={0}>
    
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Header/>
          </Grid>
        </Toolbar>
      </AppBar>
      }
      {
        loading &&
        <LinearProgress />
      }
      <div></div>
      <div sx={{
        margin:(theme)=> theme.spacing(2),
      }}>
        {props.children}
      </div>
    </Paper>
  );
}


export default Content;
