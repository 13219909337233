// export const url = "http://192.168.1.30:4089/"
// export const url = "http://192.168.0.4:4089/"
// export const url = "http://localhost:4011/"
// export const url = "http://electuraapp.ebslon.com:4039/ "
export const url = "https://admin.jshpackagings.com/api/";
// export const url="http://backend.electura.co/"

export const generateUrl = (file) => {
  return `${url}/uploads/${file}`;
};
