// import Select from 'react-select';
import { MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Content from '../../components/Content';
import { createOrder, getOrderById, updateOrderStatus } from '../../services/Order';
import { OrderStatus } from '../../utils/OrderStatus';

export default function OrderEdit() {
    const params = useParams();
    const history = useHistory();
    const [userObj, setUserObj] = useState({});
    const [orderStatusArr, setOrderStatusArr] = useState([]);
    const [message, setMessage] = useState("");
    const [orderObj, setOrderObj] = useState({});
    const [orderStatusVal, setOrderStatusVal] = useState("");
    const handleSubmit = async () => {
        try {
            let obj = {
                status: orderStatusVal,
                message
            }


            console.log(obj)
            const { data: res } = await updateOrderStatus(params.id, obj);
            if (res.success) {
                alert(res.message)
            }
            history.goBack()
        } catch (error) {
            console.error(error)
            alert(error)
        }
    }




    const handleGetOrderById = async () => {
        try {
            const { data: res } = await getOrderById(params.id);
            if (res.success) {
                setUserObj(res.data.userObj)
                console.log(res.data.status)
                setOrderStatusVal(res.data.status)
                let tempStatus = OrderStatus
                let index = OrderStatus.findIndex(el => el.name == res.data.status)
                setOrderStatusArr(tempStatus.map((el, i) => {
                    if (i <= index)
                        el.disabled = true
                    else
                        el.disabled = false

                    return el
                }))


                setOrderObj(res.data)
            }
        } catch (error) {
            console.error(error)
            alert(error)
        }
    }






    useEffect(() => {
        handleGetOrderById()
    }, [])


    return (
        <Content>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '50ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <Typography variant="h4" gutterBottom component="div">
                    Change Order Status
                </Typography>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label>User Name</label>
                    <TextField id="outlined-basic" disabled value={userObj.name} variant="outlined" />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label>Email</label>
                    <TextField id="outlined-basic" disabled value={userObj.email} variant="outlined" />
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label>Order Status</label>
                    <Select value={orderStatusVal} onChange={(e) => setOrderStatusVal(e.target.value)}>
                        <MenuItem value={''} >Please select a value</MenuItem>
                        {
                            orderStatusArr.map((el, index) => <MenuItem disabled={el.disabled} key={index} value={el.name}>{el.name}</MenuItem>)
                        }
                    </Select>
                </div>

                <label>Previous Messages</label>
                <div style={{ display: "flex", flexDirection: "column", border: "solid 1px #ccc", borderRadius: 5, padding: 10 }}>
                    {
                        orderObj?.messageArr?.map((el, index) => {
                            return (
                                <div style={{ backgroundColor: "#eee", margin: 5, padding: 4, borderRadius: 5 }}> <span><b>{index + 1}.)</b></span> {el.message}</div>
                            )
                        })
                    }
                </div>


                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label>Message</label>
                    <TextField id="outlined-basic" onChange={(e) => setMessage(e.target.value)} variant="outlined" value={message} />
                </div>
                <Stack spacing={2} direction="row">
                    <Button variant="contained" onClick={() => handleSubmit()}>Submit</Button>
                </Stack>
            </Box>
        </Content>
    )
}
