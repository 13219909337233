import { axiosApiInstance } from '../App';
import { url } from './constants'
const axios = require('axios');


const serverUrl = `${url}globalConstraints`

export const globalConstraintsAdd = async (formData) => {
    return axiosApiInstance.post(`${serverUrl}/`, formData)
}

export const getAllglobalConstraints = async () => {
    return axiosApiInstance.get(`${serverUrl}/`)
}

export const getglobalConstraintsById = async (id) => {
    return axiosApiInstance.get(`${serverUrl}/getById/${id}`)
}

export const deleteglobalConstraints = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`)
}

export const editglobalConstraints = async (id, obj) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, obj)
}
