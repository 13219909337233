import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Navigator from './Navigator';
import Header from './Header';
import { dashboardroutes } from './dashboard.route';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../screens/dashboard/Dashboard'

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        JSH
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}


const drawerWidth = 256;

export default function Paperbase() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        {isSmUp ? null : (
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        )}

        <Navigator
          PaperProps={{ style: { width: drawerWidth } }}
          sx={{ display: { sm: 'block', xs: 'none' } }}
        />
      </Box>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', maxWidth: isSmUp ? `calc(100vw - ${drawerWidth}px )` : '100vw' }}>
        <Switch>
          <Route path='/dashboard' exact={true}>
            <Dashboard />
          </Route>
          {
            dashboardroutes.map(({ children }, i) => (
              <React.Fragment key={`${i}`}>
                {
                  children.map(({ path, component: Component, useTabs, tabs, title }, j) => {
                    if (useTabs) {
                      return (
                        <React.Fragment key={`${i}_${j}`}>
                          {
                            tabs.map(({ path, component: Component }, k) => (
                              <Route exact={true} path={'/dashboard' + path} key={`${i}_${j}_${k}`}>
                                <Header onDrawerToggle={handleDrawerToggle} useTabs={useTabs} tabs={tabs} key={`${i}_${j}`} title={title} />
                                <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>
                                  <Component />
                                </Box>
                              </Route>
                            ))
                          }
                        </React.Fragment>
                      )
                    }
                    else {
                      return (
                        <React.Fragment key={`${i}_${j}`}>
                          <Route exact={true} path={'/dashboard' + path}>
                            <Header onDrawerToggle={handleDrawerToggle} useTabs={useTabs} key={`${i}_${j}`} title={title} />
                            <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>
                              <Component />
                            </Box>
                          </Route>
                        </React.Fragment>
                      )
                    }
                  })
                }
              </React.Fragment>
            ))
          }
        </Switch>

        <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>
          <Copyright />
        </Box>
      </Box>
    </Box>
  );
}
