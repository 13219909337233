import React, { useState, useEffect } from 'react'
import ContentTable from '../../components/ContentTable'
import { deleteClass, getAllClasses } from '../../services/Classes';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { getAllUsers, removeUserById } from '../../services/User';
import { getAllOrders, getAllOrdersByUserId, updateOrderStatus } from '../../services/Order';
import { url } from '../../services/constants';
import { useHistory } from 'react-router-dom';
import { OrderStatus } from '../../utils/OrderStatus';
import { MenuItem, Select } from '@mui/material';
import { useParams } from 'react-router-dom';

export default function OrderByUserId() {
    const history = useHistory();
    const params = useParams()
    const [orderArr, setOrderArr] = useState([]);
    const [loading, setLoading] = useState(false)
    const [orderStatusVal, setOrderStatusVal] = useState("");
    const [orderStatusArr, setOrderStatusArr] = useState(OrderStatus);


    const getUsers = async () => {
        setLoading(true)
        try {
            const { data: res } = await getAllOrdersByUserId(params.id);
            if (res.success) {
                setOrderArr(res.data.map((el, i) => {
                    let tempIndex = OrderStatus.findIndex(ele => ele.name == el.status)
                    el.orderStatusArr = OrderStatus.map((elx, innerIndex) => {
                        let obj = { ...elx, disabled: tempIndex >= innerIndex }
                        return obj

                    })
                    return el
                }))

            }
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }


    const handleOrderUpdate = async (id, value) => {
        try {
            let obj = {
                status: value,
                message: ""
            }
            const { data: res } = await updateOrderStatus(id, obj);
            if (res.success) {
                alert(res.message)
            }
            getUsers()
        } catch (error) {
            console.error(error)
            alert(error)
        }
        // history.push(`/dashboard/order/edit/${id}`)
    }


    const handleUserDelete = async (id) => {
        try {
            const { data: res } = await removeUserById(id);
            if (res.success) {
                getUsers()
                alert(res.message)

            }
        } catch (error) {
            console.error(error)
        }
    }


    const checkDisabled = (status, name) => {
        if (status == name) {
            return true
        }
        else {
            return false
        }
    }

    useEffect(() => {
        getUsers()
    }, [])

    return (
        <div>
            <ContentTable
                loading={loading}
                reload={getUsers}
                data={orderArr}
                columns={[
                    {
                        name: 'Name',
                        cell: row => <div style={{ cursor: "pointer" }} onClick={() => history.push(`/dashboard/order/viewByUserId/${row.userId}`)}>{row.userName}</div>,
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Company Name',
                        cell: row => <div style={{ cursor: "pointer" }} onClick={() => history.push(`/dashboard/order/viewByUserId/${row.userId}`)}>{row.companyName ? row.companyName : "NA"}</div>,
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Invoice Number',
                        selector: row => row?.invoiceNumber ? row?.invoiceNumber : "NA",
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Invoice Value',
                        selector: row => row?.invoiceValue ? row?.invoiceValue : "NA",
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Description',
                        selector: row => row?.description ? row?.description : "NA",
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'File',
                        cell: row => row.fileUrl ? <a href={`${url}uploads/${row.fileUrl}`} download target="_blank">Download Invoice</a> : <div>No File Uploaded</div>,
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Message',
                        cell: row => <div>
                            {row.messageArr.map(el => {
                                return (
                                    <div>{el.message},</div>
                                )
                            })
                            }

                        </div>,
                    },
                    {
                        name: 'Payment Status',
                        cell: row => row.paymentStatus,
                    },

                    {
                        name: 'Invoice Date',
                        selector: row => row.invoiceDate ? row.invoiceDate : "",
                        cell: row => row.invoiceDate ? new Date(row.invoiceDate).toDateString() : "NA",
                        sortable: true,
                        searchable: true,
                    },
                    {

                        name: 'Update',
                        cell: row => <div>
                            <Select value={row.status} onChange={(e) => handleOrderUpdate(row._id, e.target.value)}>
                                <MenuItem value={''} >Please select a value</MenuItem>
                                {
                                    row.orderStatusArr.map((el, index) => <MenuItem disabled={el.disabled} key={index} value={el.name}>{el.name}</MenuItem>)
                                }
                            </Select>
                            {/* <Stack spacing={2} direction="row">
                                <Button disabled={row.status == "DELIVERED"} variant="contained" color="info" onClick={() => handleOrderUpdate(row._id)}>Change status</Button>
                            </Stack> */}
                        </div>,
                    },
                    {

                        name: 'Delete',
                        cell: row => <div>
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color="error" onClick={() => handleUserDelete(row._id)}>Delete</Button>
                            </Stack>
                        </div>,
                    }
                ]}
            />
        </div>
    )
}
