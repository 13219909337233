import { axiosApiInstance } from "../App";
import { url } from "./constants";
const axios = require("axios");

const serverUrl = `${url}order`;

export const createOrder = async (obj) => {
  return axiosApiInstance.post(`${serverUrl}/`, obj);
};
export const getAllOrders = async () => {
  return axiosApiInstance.get(`${serverUrl}/`);
};
export const getAllOrdersByUserId = async (id) => {
  return axiosApiInstance.get(`${serverUrl}/getByUserId/${id}`);
};
export const getOrderById = async (id) => {
  return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};
export const updateOrderStatus = async (id, obj) => {
  return await axiosApiInstance.patch(
    `${serverUrl}/updateOrderStatus/${id}`,
    obj
  );
};
export const updatePaymentStatus = async (id, obj) => {
  return await axiosApiInstance.patch(
    `${serverUrl}/updateOrderPayment/${id}`,
    obj
  );
};

export const delteOrder = async (id) => {
  return await axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};
