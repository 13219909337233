import { MenuItem, TextareaAutosize } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Content from "../../components/Content";
import { getAllCategory, getByParentId } from "../../services/Category";
import { editproduct, getproductById, productAdd, updateImageById } from "../../services/product";

import Select from "react-select";
export default function ProductEdit() {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [categoryArr, setCategoryArr] = useState([]);
  const [description, setDescription] = useState("");
  const [currentCategoryArr, setCurrentCategoryArr] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  ///////selected arrays
  const [selectedCategoriesArr, setSelectedCategoriesArr] = useState([]);
  const params = useParams();

  const [imageArr, setImageArr] = useState([]);
  const [displayImageArr, setDisplayImageArr] = useState([]);
  const [selectedCategoryObj, setSelectedCategoryObj] = useState({});

  const getProduct = async (arr) => {
    try {
      let { data: res } = await getproductById(params.id);
      if (res.success) {
        setName(res.data.name);
        setPrice(res.data.price);
        setDescription(res.data.description);
        // console.log(res.data);
        let tempIndex = arr.findIndex((el) => el._id == res.data.categoryId);
        console.log(arr);
        console.log(tempIndex);
        if (tempIndex != -1) {
          //   console.log([{ ...arr[tempIndex] }]);
          setSelectedCategoryObj(arr[tempIndex]);
        }
        // setSelectedCategoriesArr(res.data.savedCategoryArr);
        setSelectedCategoryId(res.data.categoryId);

        // setImageArr(res.data.image);
        // setDisplayImageArr(res.data.image);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubmit = async () => {
    try {
      let catId = selectedCategoryObj?._id;
      let obj = {
        name,
        price,
        categoryId: catId,
        description: description,
        categoryArr: [{ categoryId: selectedCategoryObj?._id }],
      };
      console.log(obj);
      const { data: res } = await editproduct(params.id, obj);
      if (res.success) {
        if (displayImageArr && displayImageArr.length > 0) {
          for (let el of imageArr) {
            let tempFormData = new FormData();
            tempFormData.append("file", el);
            let response = await updateImageById(params.id, tempFormData);
            if (response.data.success) {
              alert(response.data.message);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  const handleGetCategory = async () => {
    try {
      let { data: res } = await getAllCategory();
      if (res.success) {
        console.log(res.data);
        setCategoryArr(
          res.data.map((el) => {
            let obj = {
              value: el._id,
              label: el.name,
              ...el,
            };
            return obj;
          })
        );
        setCurrentCategoryArr([
          res.data.map((el) => {
            let obj = {
              value: el._id,
              label: el.name,
              ...el,
            };
            return obj;
          }),
        ]);
        let tempArr = res.data.map((el) => {
          let obj = {
            value: el._id,
            label: el.name,
            ...el,
          };
          return obj;
        });
        console.log(tempArr, "TEp");

        getProduct(tempArr);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleCategorySelection = async (e) => {
    setSelectedCategoryObj(e);

    // if (e) {
    //   e = JSON.parse(e);
    //   try {
    //     const { data: res } = await getByParentId(e._id);
    //     if (res.success) {
    //       let tempArr = [...currentCategoryArr];
    //       setSelectedCategoryId(e._id);
    //       let tempCategoryArr = [...selectedCategoriesArr];
    //       if (selectedCategoriesArr.findIndex((el) => el.level > e.level)) {
    //         tempCategoryArr = tempCategoryArr.filter((el) => el.level < e.level);
    //       }
    //       tempCategoryArr.push(e);
    //       setSelectedCategoriesArr(tempCategoryArr);
    //       if (res.data.length > 0) {
    //         if (tempArr.findIndex((el) => el.some((elx) => elx.level > e.level)) != -1) {
    //           tempArr = tempArr.filter((el) => el.some((elx) => elx.level <= e.level));
    //           console.log(tempArr, "tempArr");
    //         }
    //         tempArr.push(
    //           res.data.map((el) => {
    //             let obj = {
    //               value: el._id,
    //               label: el.name,
    //               ...el,
    //             };
    //             return obj;
    //           })
    //         );
    //       }
    //       console.log(tempArr, "TEp");
    //       setCurrentCategoryArr([...tempArr]);
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }

    //   console.log(e);
    // } else {
    //   let tempArr = [...currentCategoryArr];
    //   let tempCategoryArr = [...selectedCategoriesArr];
    //   if (selectedCategoriesArr.findIndex((el) => el.level > e.level)) {
    //     tempCategoryArr = tempCategoryArr.filter((el, i) => i < index);
    //   }
    //   tempCategoryArr.push(e);
    //   setSelectedCategoriesArr(tempCategoryArr);
    //   tempArr = tempArr.filter((el, i) => i <= index);
    //   setCurrentCategoryArr([...tempArr]);
    // }
  };

  const handleFileSelection = (event) => {
    if (event.target.files[0] && event.target.files[0].name) {
      let tempArr = [...imageArr];
      tempArr.push(event.target.files[0]);
      console.log(tempArr);
      setImageArr([...tempArr]);

      let tempDisplayArr = [...displayImageArr];
      tempDisplayArr.push(event.target.files[0].name);

      setDisplayImageArr(tempDisplayArr);
      console.log(tempDisplayArr);
    }
  };
  const handleImageDelete = (index) => {
    let arr = [...displayImageArr];
    arr.splice(index, 1);
    setDisplayImageArr([...arr]);

    let tempArr = [...imageArr];
    tempArr.splice(index, 1);
    setImageArr([...tempArr]);
  };

  useEffect(() => {
    handleGetCategory();
  }, []);

  return (
    <Content>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "100ch" },
          display: "flex",
          flexDirection: "column",
        }}
        noValidate
        autoComplete="off"
      >
        <Typography variant="h4" gutterBottom component="div">
          Edit Product
        </Typography>
        <TextField id="outlined-basic" label="Name" onChange={(e) => setName(e.target.value)} variant="outlined" value={name} />
        <TextField id="outlined-basic" label="Description" onChange={(e) => setDescription(e.target.value)} variant="outlined" value={description} />

        <TextField id="outlined-basic" type="number" label="Price" onChange={(e) => setPrice(e.target.value)} variant="outlined" value={price} />

        <label>Categories</label>
        {/* {currentCategoryArr.map((el, index) => {
          return (
            <>
              <Select key={index} value={selectedCategoriesArr[index] ? JSON.stringify(selectedCategoriesArr[index]) : ""} onChange={(e) => handleCategorySelection(e.target.value, index)}>
                <MenuItem value={""}>Please select a value</MenuItem>
                {el.map((ele) => (
                  <MenuItem key={ele._id} value={JSON.stringify(ele)}>
                    {ele.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          );
        })} */}
        <Select placeholder="Select Category..." options={categoryArr} onChange={(e) => handleCategorySelection(e)} value={selectedCategoryObj} />

        <label>Product Images</label>
        <input type="file" onChange={(e) => handleFileSelection(e)} />
        {displayImageArr &&
          displayImageArr.map((el, index) => {
            return (
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <span key={index}>
                  {index + 1}. {el}
                </span>
                <Button variant="contained" color="error" onClick={() => handleImageDelete(index)}>
                  Delete
                </Button>
              </div>
            );
          })}
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={() => handleSubmit()}>
            Submit
          </Button>
        </Stack>
      </Box>
    </Content>
  );
}
