import React from 'react'
import { AuthProvider } from './AuthContext'


export default function RootContext(props) {
    return (
        <AuthProvider>
            {props.children}
        </AuthProvider>
    )
}
