import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Content from '../../components/Content';
import { editCategory, getCategoryById } from '../../services/Category';

export default function CategoryEdit() {
  const params = useParams();
  const history = useHistory();

  const [name, setName] = useState('');
  const handleSubmit = async () => {
    try {
      let obj = {
        name,
      }
      const { data: res } = await editCategory(params.id, obj);
      if (res.success) {
        alert(res.message)
      }
      history.goBack()
    } catch (error) {
      console.error(error)
      alert(error)
    }
  }



  const handleGet = async () => {
    try {
      const { data: res } = await getCategoryById(params.id);
      if (res.data) {
        setName(res.data.name)
      }
    } catch (error) {
      console.error(error)
      alert(error)
    }
  }




  useEffect(() => {
    handleGet()
  }, [])




  return (
    <Content>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '50ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Typography variant="h4" gutterBottom component="div">
          Edit Category
        </Typography>

        <TextField id="outlined-basic" label="Category Name" onChange={(e) => setName(e.target.value)} variant="outlined" value={name} />

        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={() => handleSubmit()}>Update</Button>
        </Stack>
      </Box>
    </Content>
  )
}
