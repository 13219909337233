import { url } from './constants'
import { axiosApiInstance } from '../App';

const serverUrl = `${url}product`

export const productAdd = async (formData) => {
    return axiosApiInstance.post(`${serverUrl}/`, formData)
}

export const getAllproducts = async () => {
    return axiosApiInstance.get(`${serverUrl}/`)
}

export const deleteproduct = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`)
}

export const editproduct = async (id, obj) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, obj)
}

export const getproductById = async (id) => {
    return axiosApiInstance.get(`${serverUrl}/getById/${id}`)
}
export const updateImageById = async (id, obj) => {
    return axiosApiInstance.patch(`${serverUrl}/updateImageById/${id}`, obj)
}
