import { url } from './constants'
import { axiosApiInstance } from '../App';
const axios = require('axios');


const serverUrl = `${url}ply`

export const plyAdd = async (formData) => {
    return axiosApiInstance.post(`${serverUrl}/`, formData)
}


export const getAllPly = async () => {
    return axiosApiInstance.get(`${serverUrl}/`)
}

export const deletePly = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`)
}



