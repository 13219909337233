import React, { useState, useEffect } from "react";
import ContentTable from "../../components/ContentTable";
import {url} from "../../services/constants"

import {
  imageAdd,
  getAllBanner,
  deleteBanner,
  editImage,
  editBanner
} from "../../services/Banner";

import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";

export default function BannerView(props) {


  const [imageArr, setImageArr] = useState([]);
  const [imageObj, setImageObj] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const params = useParams();

  const handleGetImage = async () => {
    setLoading(true);
    try {
      if (!params.imageId) {
        // get level 1 only
        setImageObj(null);
        const { data: res } = await getAllBanner();
        console.log(res,"Rese23534534534  ")
        if (res.success) {
          console.log(res.data);
          setImageArr(res.data);
        }
      } else {
        getImageObj();
        // const { data: res } = await getByParentId(params.imageId);
        // if (res.success) {
        //   console.log(res.data)
        //   setImageArr(res.data)
        // }
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const getImageObj = async () => {
    try {
      // const { data: res } = await getCategoryById(params.imageId);
      // if (res.success) {
      //   console.log(res.data)
      //   setImageObj(res.data)
      // }
    } catch (err) {
      console.error(err);
    }
  };

  const del = async (id) => {
    try {
      if (window.confirm("Are you sure you wan to delete?")) {
        let res = await deleteBanner(id);
        if (res.data?.message) {
          alert(res?.data.message);
        }
        setLoading(true);
        handleGetImage();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetImage();
  }, [params]);

  return (
    <div>
      <ContentTable
        loading={loading}
        reload={handleGetImage}
        data={imageArr}
        extraButtonFn={() =>
          params?.imageId
            ? history.push(`/dashboard/bannerAdd/add/${params.imageId}`)
            : history.push(`/dashboard/bannerAdd/add/`)
        }
        extraButtonText={"Add"}
        title={imageObj ? `Sub Categories of ${imageObj?.image}` : ""}
        columns={[
          {
            name: "Banner",
            // selector: (row) => <img src={row?.image}  alt="" />,
            // selector: (row) =>   <img src={`${url}/${row?.image}`} style={{ width: "100px", height: "100px" }} alt="" />,
            selector: (row) =>   <img src={`${url}uploads/${row?.image}`} style={{ width: "100px", height: "100px" }} alt="" />,
            // sortable: true,
            searchable: true,
          },



          // {
          //   name: 'Add',
          //   cell: row => <Button variant='contained' onClick={() => history.push(`/dashboard/category/add/${row._id}`)}>  Add </Button>,
          // },
          // {
          //   name: "View",
          //   cell: (row) => (
          //     <Button
          //       variant="contained"
          //       onClick={() =>
          //         history.push(`/dashboard/category/sub/${row._id}`)
          //       }
          //     >
          //       {" "}
          //       View{" "}
          //     </Button>
          //   ),
          // },
          // {
          //   name: "Edit",
          //   cell: (row) => (
          //     <Button
          //       variant="contained"
          //       onClick={() =>
          //         history.push(`/dashboard/category/edit/${row._id}`)
          //       }
          //     >
          //       {" "}
          //       Edit{" "}
          //     </Button>
          //   ),
          // },
          {
            name: "Delete",
            cell: (row) => (
              <Button
                variant="contained"
                color="error"
                onClick={() => del(row._id)}
              >
                Delete
              </Button>
            ),
          },
        ]}
      />
    </div>
  );
}
