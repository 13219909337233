import React, { useState, useEffect } from 'react'
import ContentTable from '../../components/ContentTable'
import { deleteCategory, getAllCategory, getByParentId } from '../../services/Category';
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { deletesizes, getAllsizes } from '../../services/Size';
import { getAllglobalConstraints } from '../../services/GlobalConstraints';
export default function GlobalConstraintsView(props) {

    const [sizeArr, setSizeArr] = useState([]);
    const [loading, setLoading] = useState(false)
    const history = useHistory();
    const params = useParams();

    const handleGetCategory = async () => {
        setLoading(true)
        try {
            const { data: res } = await getAllglobalConstraints();
            if (res.success) {
                console.log(res.data)
                setSizeArr(res.data)
            }
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    const del = async (id) => {
        try {
            if (window.confirm('Are you sure you wan to delete?')) {
                let res = await deletesizes(id)
                if (res.data?.message) {
                    alert(res?.data.message)
                }
                setLoading(true);
                handleGetCategory();
            }
        } catch (error) {
            console.error(error)
        }
    }


    useEffect(() => {
        handleGetCategory()
    }, [params])

    return (
        <div>
            <ContentTable
                loading={loading}
                reload={handleGetCategory}
                data={sizeArr}
                columns={[
                    {
                        name: 'Cost Virgin',
                        selector: row => row.costVirgin,
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Cost Semi',
                        selector: row => row.costSemi,
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Cost Of Sticker',
                        selector: row => row.costOfStickers,
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Edit',
                        cell: row => <Button variant='contained' onClick={() => history.push(`/dashboard/globalConstraints/edit/${row._id}`)}>  Edit </Button>,
                    },
                    {
                        name: 'Delete',
                        cell: row => <Button variant='contained' color='error' onClick={() => del(row._id)} > Delete</Button>,
                    },
                ]}
            />
        </div>
    )
}
