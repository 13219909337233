import { axiosApiInstance } from '../App';
import { url } from './constants'
const axios = require('axios');


const serverUrl = `${url}subject`

export const subjectAdd = async (formData) => {
    return axiosApiInstance.post(`${serverUrl}`, formData)
}


export const getAllSubjects = async () => {
    return axiosApiInstance.get(`${serverUrl}/`)
}


export const deleteSubject=async(id)=>{
    return axiosApiInstance.patch(`${serverUrl}/deleteById/${id}`)
}

export const uploadImage = async (id,obj) => {
    return axiosApiInstance.patch(`${serverUrl}/uploadImage/${id}`,obj)
}
