import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Content from '../../components/Content';
import { categoryAdd } from '../../services/Category';
import { getAllUsers } from '../../services/User';
import Select from 'react-select';
import { createOrder } from '../../services/Order';
export default function OrderAdd() {

    const params = useParams();
    const history = useHistory();
    const [query, setQuery] = useState("");
    const [userArr, setUserArr] = useState([]);
    const [mainUserArr, setMainUserArr] = useState([]);
    const [name, setName] = useState('');
    const [file, setFile] = useState("");
    const [message, setMessage] = useState("");
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [invoiceValue, setInvoiceValue] = useState("");
    const [userId, setUserId] = useState("");
    const [description, setDescription] = useState("");
    const [invoiceDate, setInvoiceDate] = useState(new Date());
    const handleSubmit = async () => {
        try {
            let obj = {
                userId,
                file,
                invoiceNumber,
                invoiceValue,
                description,
                messageArr: [{ message: message }],
            }

            let formData = new FormData()
            formData.append("userId", userId);
            formData.append("file", file);
            formData.append("description", description);
            formData.append("invoiceNumber", invoiceNumber);
            formData.append("invoiceValue", invoiceValue);
            formData.append("invoiceDate", invoiceDate);
            formData.append("messageArr", JSON.stringify([{ message: message }]));

            console.log(obj)
            const { data: res } = await createOrder(formData);
            if (res.success) {
                alert(res.message)
            }
            history.goBack()
        } catch (error) {
            console.error(error)
            alert(error)
        }
    }


    const handleGetUsers = async () => {
        try {
            const { data: res } = await getAllUsers();
            if (res.success) {
                setUserArr(res.data.map(el => {
                    el.value = el._id
                    el.label = `${el.name} | ${el.phone} | ${el.email}`
                    return el
                }))
                setMainUserArr(res.data)
            }
        } catch (error) {
            console.error(error)
            alert(error)
        }
    }



    const handleFilterUsers = (val) => {
        console.log(val)
        let tempArr = mainUserArr.filter(el => `${el.name}`.toLowerCase().includes(`${val}`.toLowerCase()) || `${el.email}`.toLowerCase().includes(`${val}`.toLowerCase()) || `${el.phone}`.toLowerCase().includes(`${val}`.toLowerCase()))
        setUserArr(tempArr)
    }


    useEffect(() => {
        handleGetUsers()
    }, [])


    return (
        <Content>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '50ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <Typography variant="h4" gutterBottom component="div">
                    Add Order
                </Typography>
                <label>Select User</label>
                <Select
                    options={userArr}
                    escapeClearsValue
                    isClearable
                    filterOption={false}
                    onInputChange={val => handleFilterUsers(val)}
                    onChange={opt => { console.log(opt); setUserId(opt._id) }}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label>Upload File</label>
                    <TextField id="outlined-basic" accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type={"file"} onChange={(e) => setFile(e.target.files[0])} variant="outlined" />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label>Invoice Value</label>
                    <TextField id="outlined-basic" type={"number"} onChange={(e) => setInvoiceValue(e.target.value)} variant="outlined" value={invoiceValue} />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label>Invoice Number</label>
                    <TextField id="outlined-basic" onChange={(e) => setInvoiceNumber(e.target.value)} variant="outlined" value={invoiceNumber} />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label>Invoice Date</label>
                    <TextField id="outlined-basic" type={"date"} onChange={(e) => setInvoiceDate(e.target.value)} variant="outlined" value={invoiceDate} />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label>Description</label>
                    <TextField id="outlined-basic" onChange={(e) => setDescription(e.target.value)} variant="outlined" value={description} />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label>Message</label>
                    <TextField id="outlined-basic" onChange={(e) => setMessage(e.target.value)} variant="outlined" value={message} />
                </div>
                <Stack spacing={2} direction="row">
                    <Button variant="contained" onClick={() => handleSubmit()}>Submit</Button>
                </Stack>
            </Box>
        </Content>
    )
}
