export const OrderStatus = [
  { name: "ORDERED" },
  { name: "DISPATCHED" },
  { name: "SHIPPED" },
  { name: "DELIVERED" },
];
export const PaymentStatus = [
  { name: "PARTIALLY PAID" },
  { name: "PAID" },
  { name: "NOT PAID" },
];
