import React, { useState, useEffect } from 'react'
import ContentTable from '../../components/ContentTable'
import { deleteCategory, getAllCategory, getByParentId, getCategoryById } from '../../services/Category';
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { approveUser, getAllUsers } from '../../services/User';
export default function UsersView() {

    const [userArr, setUserArr] = useState([]);
    const [loading, setLoading] = useState(false)
    const history = useHistory();
    const params = useParams();

    const handleGetUsers = async () => {
        setLoading(true)
        try {
            const { data: res } = await getAllUsers();
            if (res.success) {
                console.log(res.data)
                setUserArr(res.data)
            }
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }


    const handleUserApproveToggle = async (id) => {
        setLoading(true)
        try {
            const { data: res } = await approveUser(id);
            if (res.success) {
                alert(res.message)
                console.log(res.data)
                handleGetUsers()
            }
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }



    useEffect(() => {
        handleGetUsers()
    }, [params])

    return (
        <div>
            <ContentTable
                loading={loading}
                reload={handleGetUsers}
                data={userArr}
                title={"Users"}
                columns={[
                    {
                        name: 'Name',
                        selector: row => row.name,
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Company Name',
                        selector: row => row.companyName ? row.companyName : "NA",
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Email',
                        selector: row => row.email,
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Phone',
                        selector: row => row.phone,
                        sortable: true,
                        searchable: true,
                    },
                    {
                        name: 'Approval Status',
                        selector: row => row.isApproved ? "Approved" : "Not Approved Yet",
                        sortable: true,
                        searchable: true,
                    },
                    // {
                    //   name: 'Add',
                    //   cell: row => <Button variant='contained' onClick={() => history.push(`/dashboard/category/add/${row._id}`)}>  Add </Button>,
                    // },

                    {
                        name: 'Approve/UnApprove',
                        cell: row => <Button variant='contained' onClick={() => handleUserApproveToggle(row._id)}>  {row.isApproved ? "Unapprove" : "Approve"} </Button>,
                    },

                ]}
            />
        </div>
    )
}
